// import SockJS from 'sockjs-client'
import msgAction from '@/components/msgAction.vue';
import store from '@/store'
import { bus } from 'vue-fant-base-plus'
import { Notification } from 'element-ui';
import Vue from 'vue'
const debug = import.meta.env.VITE_NODE_ENV !== 'production'

let msg = {
    webSocket: null,
    disconnect () {
        if (this.webSocket && this.webSocket.readyState == 1) {
            this.webSocket.close()
            this.webSocket = null
        }
    },
    subscribe(cb) {
        const version = JSON.parse(window.sessionStorage.getItem("version"));
        if(!version) return;
        const wsurl = bus.$FULL_MESSAGE_URL+'/message?token=' + sessionStorage.getItem('token')
        const curwsurl = debug?('ws://' + location.host + wsurl):wsurl
        // const curwsurl = 'ws://192.168.0.203:8000/message/v2/sockjs/message?token=' + sessionStorage.getItem('token')
        try {
            this.webSocket = new WebSocket(curwsurl) //webSocket对应地址
        } catch(err) {
            this.errorCallback(cb)
            return new Error(err);
        }
        this.webSocket.onopen = () =>{
            this.connectCallback(cb)
        }
        this.webSocket.onclose = (err) =>{
        }
    },
    connectCallback(cb) {
        if (cb && typeof cb === 'function') {
            cb(true)
        }
    },
    errorCallback(cb) {
        if (cb && typeof cb === 'function') {
            cb(false)
        }
    },
    onClose(){

    },
    onMessage(message) {
        let data = JSON.parse(message.data)
        if (debug) {
            console.log('websocket接收: ', data)
        }
        if(data.action == '__READ__'){
            store.dispatch('ChangeMsgRefresh',true);
        }
        if(data.action == 'view' && data.businessId){
            //消息按钮的徽标+1
            store.dispatch('ComputeUnreadMsgCount',true);
            store.dispatch('ChangeMsgRefresh',true);
            bus.$emit('Notification', data)
        }
    }
}

window.onbeforeunload = function() {
    msg.disconnect()
}

export default msg
