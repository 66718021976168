export default class Message{
    constructor(){
        
    }
    public ERROR:string = '服务端异常，请稍后重试'
    public DELETESUCCESS:string = '删除成功'
    public DELETEHINT:string = '您确定要删除当前选中数据吗？'
    public DELETEROWHINT:string = '您确定要删除当前数据吗？'
    public CLOSEFORM:string = '数据未保存，是否确认要关闭窗口？'
    public SUBMITSUCCESS:string = '提交成功'
    public SAVESUCCESS:string = '保存成功'
    public EDITSUCCESS:string = "编辑成功"
    public ONSHELFSUCCESS:string = "上架成功"
    public OFFSHELFSUCCESS:string = "下架成功"
    public MODIFICATIONSUCCESS:string = '修改成功'
    public REFUNDHINT:string = '您确定同意退款吗？'
    public NOREFUNDHINT:string = '您确定拒绝退款吗？'
    public IMGSIZE:number = 5120
    public TENIMGSIZE:number = 10240
    public ONEIMGSIZE:number = 1024
    public TWENTYIMGSIZE:number = 20480
    public IMGTYPEWARN:string = '您上传的图片格式不符合要求'
    public IMGSIZEWARN:string = '您上传的图片大小不符合要求'
    public RESETPWD:string = '密码重置成功'
    public VERIFICATIONHINT:string = '验证码发送成功'
    public PAYHINT:string = '退款成功'
    public NOREFUND:string = '拒绝退款成功'
    public SENDCARGO:string = '发货成功'
    public UPLOADPICHINT:string = '图片最多上传9张'
    public LOADINGMSG:string = '加载中'
    public OFFSUCCESS:string="禁用成功！"
    public ONSUCCESS:string="启用成功！"

    public UPPICSUCCESS:string="图片上传成功"
    public UPPICERROR:string="图片上传失败"
    public UPFILESUCCESS:string="文件上传成功"
    public UPFILEERROR:string="文件上传失败"
    public UPPICONEM:number=1*1024*1024//kb5M
    public UPPICFIVEM:number=5*1024*1024//kb5M
    public UPPICBIGM=(v:number):string=>`图片最大不能超过${v/1024/1024}M`
    public UPPICBIGN=(v:number):string=>`图片最多不能超过${v}张`
    public UPPICBIGT=(v:Array<string>):string=>`图片类型只能是${v.join('、')}类型` //['jpg']
    public UPFILEBIGM=(v:number):string=>`文件最大不能超过${v/1024/1024}M`
    public UPFILEBIGN=(v:number):string=>`文件最多不能超过${v}张`
    public UPFILEBIGT=(v:Array<string>):string=>`文件类型只能是${v.join('、')}类型` //['jpg']
}
