import fant from '@/libs/fant-plugins'
import { mixinApp } from "./mixin.js";
import extend from './extend';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
//基于各个浏览器和系统的不同，重写了中文字的拼音排序比较
import "./pinyin-sort.js";
/* 组件全局注册 */
import fantImg from "@/components/fantImg.vue";

function Plugins(){
}
Plugins.install = (Vue, router, store)=>{
    /* 初始化公司内部插件 */
    Vue.use(fant,{store})
    /* 混入方法变量 */
    mixinApp(Vue);
    //扩展或重新集合
    Vue.use(extend);
    /* 初始化图片预览组件 */
    Vue.use(preview);

    /* 全局组件注册 */
    Vue.component("fantImg", fantImg);
    
    /**
     *路由跳转到指定模块的子菜单
    * @param {Object} code 子菜单编码
    */
    function routerGoModule(code = "/") {
        let hItem = null; //头部的子菜单
        let eItem = null; //头部的子菜单的子菜单
        let menuTree = store.getters.menuTree;
        menuTree.forEach((item) => {
            item.children.forEach((ele) => {
                if (ele.code == code) {
                    hItem = item;
                    eItem = ele;
                } else if (ele.code == null && ele.children.length > 0) {
                    //有页签页面
                    let titem = null;
                    ele.children.forEach((chi) => {
                        if (chi.code == code) {
                            hItem = item;
                            eItem = ele;
                        }
                    });
                }
            });
        });
    
        if (hItem == null) {
            if(code == "messageCenter"){
                this.$fts.systemPush(code, {name:"消息中心", addBread:false});
            }else if(code == "userSetting"){
                this.$fts.systemPush(code, {name:"个人信息", addBread:false});
            }
            return;
        }
        store.dispatch("setItem", hItem);
        window.$theMenu.$refs.navmenu.goto(eItem);
    }
    Vue.prototype.$routerGoModule = routerGoModule;
    
    Vue.prototype.$fileUplaod = function(param) {
        let urlUplaod = this.$FILE_URL + "/web/firm/file";
        let numSize = 10;
        if (param.data && param.data.size) {
            numSize = param.data.size;
        }
        if (param.data && param.data.url) {
            urlUplaod = param.data.url;
        }
        if (param.file.size > 1024 * 1024 * numSize) {
            this.$message({
                msg: "上传文件大小不能超出" + numSize + "MB",
                type: "error",
            });
            return;
        }
        var fdata = new FormData();
        fdata.append("file", param.file);
        this.$http.post(urlUplaod, fdata, {
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            if (res.ok) {
                res.param = param.data;
                param.onSuccess(res);
            } else {
                param.onError(res);
            }
        });
    };
}

export default Plugins