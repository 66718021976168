
<span style="display:inline-block;overflow:hidden;" class="clearfix" :style="{'border-radius':isCircular?'50%':'0',width:curWidth, height:curHeight, cursor:preview && !error?'pointer':''}">
    <img
        v-show="showImage && (avatar || (!avatar && !error))"
        ref="image"
        style="width:100%;height:100%;"
        :src="imgSrc" 
        :style="{'object-fit':fit}"
        :preview="preview"
        :preview-text="previewText"
        @error="onError"
        @load="load"
    />
    <div class="img-error" v-if="showImage && !avatar && error">
        <i class="el-icon-picture-outline" :style="{'font-size':curWidth.indexOf('%')==-1?`calc(${curWidth} / 2)`:''}"></i>
    </div>
    <span v-if="!showImage" @click="open"><slot></slot></span>
</span>
