import Vue from 'vue'
import App from './App.vue'
import entry from './boot/entry'
import store from "@/store";
import router from "@/router";
// import startApps from './apps'

Vue.config.productionTip = false

entry.install(Vue, router, store)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

/* 启动qiankun，访问微应用 */
// startApps()