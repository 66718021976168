import requestPlugin from '@/utils/request'
import plugins from '@/libs/plugins'
import FooltechSass from 'fant-microsass-web'
import { routerHooks } from '@/router/hooks'
import { loadConfig } from './beforeLoad'


const entry = {}

entry.install = (Vue, router, store)=>{
    /* 加载各服务接口前缀等配置 */
    loadConfig()
    
    /* 初始化插件 */
    plugins.install(Vue, router, store)
    
    requestPlugin.install()
    
    /* 应用通讯对象必须在路由钩子初始化前进行初始化 */
    Vue.use(FooltechSass, {router, store})
    /* 路由钩子 */
    routerHooks(router)
}

export default entry