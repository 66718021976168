import store from "@/store";
import { loadRoutesAndGetApps } from '@/boot/beforeLoad'
import { registerApps } from '@/apps'
import { staging } from "@/boot/beforeLoad";
import Vue from 'vue'
import devApps from '@/boot/devApps'
const prod = import.meta.env.VITE_NODE_ENV === 'production'
/* 主应用白名单 */
const whiteList = ['login', 'register', 'retrievePassword', 'status']

/**
 * 判断是否第一次加载到系统的叶子页面 
 * @param {*} to 跳转的目的路由
 * @param {*} from 上一层经过的路由
 * @returns 
 */
const isReload = (to, from)=>{
    return from.path == '/' && from.matched.length == 0 && to.name != 'transit'
}


export function routerHooks(router){
    if(sessionStorage.getItem('__fool_subapps') && sessionStorage.getItem('token')){
        const subapps = JSON.parse(sessionStorage.getItem('__fool_subapps'))
        /* 非登录情况，重新加载路由 */
        loadRoutesAndGetApps(subapps, router)
    }
    if(window.location.pathname.indexOf('/educational/bigScreen') !== -1 && !sessionStorage.getItem('token')){
        sessionStorage.removeItem('routerLoaded')
        /* 大屏链接特例 */
        router.addRoute('layout',{
            path: '/educational/*',
            name: 'educational',
            component: () => import('@/views/layout/subapp.vue')
        })
        let devAppsConfig = {}, moduleCode = 'educational'
        if(staging && sessionStorage.__fool_devAppsConfig){
            try{
                devAppsConfig = JSON.parse(sessionStorage.__fool_devAppsConfig)
            }catch(err){
                devAppsConfig = {}
            }
        }
        let entry = staging && devAppsConfig[moduleCode]&&devAppsConfig[moduleCode]?devAppsConfig[moduleCode]:(location.origin + '/' + moduleCode + '/');
        entry = entry + (entry.indexOf('?')!=-1?'&r=':'?r=') + Math.random()
        const app = prod?{
            name: moduleCode,
            entry,
            container: '#subapp-view',
            activeRule: '/' + moduleCode + '/',
        }:devApps['school.educational']
        registerApps([
            {...app, props:{}}
        ])
    }
    router.beforeEach((to, from, next) => {
        if((to.path.indexOf('/educational/bigScreen') == 0 || sessionStorage.routerLoaded) && !sessionStorage.getItem('token')){
            /* 大屏链接特例 */
            if(sessionStorage.routerLoaded && to.path == '/educational/bigScreen'){
                next()
            }else if(sessionStorage.routerLoaded){
                sessionStorage.removeItem('routerLoaded')
                next()
            }else{
                sessionStorage.setItem('routerLoaded', JSON.stringify({path: to.path, query: to.query}))
                next({path:'/educational/bigScreen', replace:true})
            }
            return ;
        }
        if(from.path == '/' && to.path.indexOf(store.state.appStore.route.name)==-1 && to.path.indexOf(store.state.appStore.route.system)==-1){
            store.commit('appStore/RESET_ROUTE')
        }
        if(from.path !== '/' && window.$ftTracking){
            window.$ftTracking.onVueLeaveRoute()
        }
        /* 准生产环境下，根据url的传值设定子系统的加载地址 */
        if(staging){
            let devAppsConfig = {}
            try{
                devAppsConfig = JSON.parse(sessionStorage.__fool_devAppsConfig)
            }catch(err){
                devAppsConfig = {}
            }
            let query = Vue.deepCopy(to.query) || {}
            let changekey = false
            for(let i in query){
                let cur = i.match(/__fool_(\S*)_host/)
                if(cur && cur[1]){
                    devAppsConfig[cur[1]] = query[i]
                    changekey = true
                    delete query[i]
                }
            }
            sessionStorage.setItem('__fool_devAppsConfig', JSON.stringify(devAppsConfig))
            if(changekey){
                location.replace(location.origin+location.pathname+'?'+Vue.serialize(query))
                return ;
            }
        }
        const token = sessionStorage.getItem('token')
        /* 刷新后从缓存在sessionStorage里面的vuex获取params的值，重新回传到刷新后的页面 */
        const route = store.state.appStore.appRoute
        let params = route.systemRouteOption.params?Object.assign({}, route.systemRouteOption.params, to.params):to.params
        if(token && to.name == route.code && !params.__fool_setParams){
            params = Object.assign({__fool_setParams:true}, params, to.params)
            next({name:to.name, params, query:to.query, replace:true})
        }
        /* 微应用白名单路径 */
        const microApps = sessionStorage.getItem('__fool_microApps')?JSON.parse(sessionStorage.getItem('__fool_microApps')):[]
        if(whiteList.includes(to.name)){
            /* 清除全部登录信息 */
            store.dispatch('ClearLogOut')
            next()
        }else if(token){
            if(to.name) { // 有 name 属性，说明是主应用的路由
                next()
            }else if(microApps.some(item => (to.path+'/').includes(item.activeRule))){
                next()
            }else{
                next({ path: '/status/404' })
            }
        }else{
            /* 清除全部登录信息 */
            store.dispatch('ClearLogOut')
            next({name:'login', replace:true})
        }
    })

    router.afterEach((to, from)=>{
        const token = sessionStorage.getItem('token')
        if(token && isReload(to, from)){
            const apps = JSON.parse(sessionStorage.getItem('__fool_microApps'))
            /* 非登录情况，重新注册微应用 */
            apps?registerApps(apps):null
        }
        if(from.path !== '/' && window.$ftTracking){
            window.$ftTracking.vueInit(location.origin+import.meta.env.BASE_URL+'/static/tracking.js')
        }
    })
}