import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import menu from './modules/menu'
import message from './modules/message'
import * as getters from './getters'

//导入日志插件
import createLogger from 'vuex/dist/logger'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
const debug = !import.meta.env.VITE_NODE_ENV === 'production'
const store = new Vuex.Store({
    modules: {
        user, menu, message
    },
    state: {
        tabs: ['index'],
        version:{},
        picVersion:{},
        //判断是否在进行刷新token操作
        isRefreshing:false,
        navPath:{},
        currentTab:'',
        configTypes:{}
    },
    getters,
    mutations: {
        setConfigTypes(state, types){
            state.configTypes = types
        },
        addTab(state, name) {
            if (state.tabs.indexOf(name) == -1) {
                state.tabs.push(name);
                let tabs = state.tabs.join(",");
                if (typeof window !== 'undefined') {
                    localStorage.setItem("tabs", tabs)
                }
            }
        },
        removeTab(state, name) {
            Vue.arrayRemove(state.tabs, name);
            let tabs = state.tabs.join(",");
            if (typeof window !== 'undefined') {
                localStorage.setItem("tabs", tabs)
            }
        },
        setTab(state, arr) {
            state.tabs = arr;
            let tabs = state.tabs.join(",");
            if (typeof window !== 'undefined') {
                localStorage.setItem("tabs", tabs)
            }
        },
        setVersion(state, version){
            let picVersion = {}
            for(let [key, value] of Object.entries(version)){
                picVersion[key] = debug?'':'/v'+ value
            }
            state.version = version
            state.picVersion = picVersion
            if (typeof window !== 'undefined') {
                sessionStorage.setItem("version", JSON.stringify(state.version))
            }
        },
        setIsRefreshing(state, isRefreshing){
            state.isRefreshing = isRefreshing;
        },
        clearNavPath(state){
            for(let key in state.navPath){
                if(state.navPath[key] instanceof Array && state.navPath[key][0]){
                    state.navPath[key] = [state.navPath[key][0]];
                }
            }
        },
        setNavPath(state, navPath){
            state.navPath[navPath.name] = navPath.value;
        },
        setCurrentTab(state, currentTab){
            state.currentTab = currentTab;
        }
    },
    strict: debug,//严格模式，发布的时候自动取消严格模式
    plugins: debug ? [
        createLogger(),
        createPersistedState({
            storage: window.sessionStorage,
            paths:['tabs', 'version', 'picVersion', 'navPath', 'currentTab', 'user','menu','message','appStore']
        })
    ] : [
        createPersistedState({
            storage: window.sessionStorage,
            paths:['tabs', 'version', 'picVersion', 'navPath', 'currentTab', 'user','menu','message','appStore']
        })
    ]
})


export default store;