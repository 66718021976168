import system from "@/api/login";
import { getToken, setToken, setName, setRefToken } from "@/utils/auth";
import router from "@/router";
import msg_ from "@/api/message";
let msg = {};

if (typeof window !== "undefined") {
  msg = msg_;
}

const user = {
  state: {
    userId: "",
    name: "",
    token: "",
    refreshToken: "",
    schoolInfo: {},
    isPlatformOperate: false
  },

  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_NAME: (state, name) => {
      state.name = name;
      setName(name);
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      if (window.$ftTracking) {
        window.$ftTracking.setToken(token);
      }
      setToken(token);
    },
    SET_REFTOKEN: (state, token) => {
      state.refreshToken = token;
      setRefToken(token);
    },
    SET_SCHOOLINFO: (state, info) => {
      state.schoolInfo = info;
    },
    SET_ISPLATFORMOPERATE: (state, info) => {
      state.isPlatformOperate = info;
    }
  },

  actions: {
    setUser: ({ commit }, user) => {
      commit("SET_USER", user);
    },
    setUserId: ({ commit }, userId) => {
      commit("SET_USERID", userId);
    },
    setSchoolInfo: ({ commit }, info) => {
      commit("SET_SCHOOLINFO", info);
    },
    // 登录
    Login({ commit }, userInfo) {
        const username = userInfo.username.trim();
        const password = userInfo.password.trim();
        const captcha = userInfo.captcha.trim();
        const captchaId = userInfo.captchaId.trim();
        return new Promise((resolve, reject) => {
            system.login(username, password,userInfo.isPlatformOperate,captcha,captchaId).then(response => {
            const data = response;
            if(data.ok) {
                //保存在vuex
                commit('SET_NAME', username);
                commit('SET_TOKEN', data.data);
                commit('SET_REFTOKEN', data.moreData.refreshToken);
                commit('SET_ISPLATFORMOPERATE', userInfo.isPlatformOperate);
                localStorage.setItem('lastTokenTime',new Date().getTime())
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    Refresh({ commit, state }, tokenJson) {
      return new Promise((resolve, reject) => {
        system
          .refresh({ oldToken: state.token, refreshToken: state.refreshToken })
          .then((response) => {
            const data = response;
            if (data.ok) {
              //保存在vuex
              //if(data.data !== state.token){
              let time = new Date().getTime();
              localStorage.setItem("lastTokenTime", time);
              if (window.frames.$vueEl) {
                window.frames.localStorage.setItem("lastTokenTime", time);
              }
              //}
              commit("SET_TOKEN", data.data);
              commit("SET_REFTOKEN", data.moreData.refreshToken);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 注册
    Regist({ commit }, userInfo) {
        const username= userInfo.username.trim();
        const password = userInfo.password.trim();
        return new Promise((resolve, reject) => {
            system.regist(username, password).then(response => {
                const data = response.data;
                commit('SET_NAME', data.name);
                commit('SET_TOKEN', data.token);
                setName(data.name);setToken(data.token);
                resolve(response);
            }).catch(error => {
                reject(error) 
            }) 
        }) 
    },
    // 获取验证码
    SendVerification({ commit }, phone) {
      return new Promise((resolve, reject) => {
        system
          .sendVerification(phone)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 检验验证码是否正确，返回指定token
    CheckVerification({ commit }, verdata) {
      return new Promise((resolve, reject) => {
        system
          .checkVerification(verdata)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 保存重置的密码
    RetrievePwd({ commit }, data) {
      return new Promise((resolve, reject) => {
        system
          .retrievePwd(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ dispatch, commit, state }) {
      dispatch("ClearLogOut");
      /* 清除权限后，自动重定向到登录页 */
      location.reload();
    },
    // 切换公司
    ChangeFirm({ dispatch, commit, state}, data){
        const id = data.firmId;
        const token = data.token;
        return new Promise((resolve, reject) => {
            system.changeFirm(id, token).then(response => {
                if(response.ok) {
                    //清除token等登录信息
                    dispatch('SwitchLogOut');
                    //把新的登录信息保存在vuex
                    commit('SET_NAME', data.name);
                    commit('SET_TOKEN', response.data);
                    commit('SET_REFTOKEN', response.moreData.refreshToken);
                    localStorage.setItem('lastTokenTime',new Date().getTime())
                }
                resolve(response);
            }).catch(error => {
                // 超时跳回登陆页面
                router.push("/login");
                reject(error)
            })
        })
    },
    // 切换
    SwitchLogOut({ commit, state }) {
      commit("SET_NAME", "");
      commit("SET_TOKEN", "");
      commit("SET_REFTOKEN", "");
      setName("");
      setToken("");
      setRefToken("");
      sessionStorage.clear();
      msg.disconnect();
      //同步到同一浏览器其他窗口的sessionStorage
      // localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      // localStorage.removeItem('sessionStorage');
      if (window.$vueEl) {
        window.$vueEl.$notify.closeAll();
      }
    },
    // 退出
    ClearLogOut({ commit, state }) {
      commit("SET_NAME", "");
      commit("SET_TOKEN", "");
      commit("SET_REFTOKEN", "");
      setName("");
      setToken("");
      setRefToken("");
      const devHost = sessionStorage.__fool_devAppsConfig;
      sessionStorage.clear();
      sessionStorage.setItem("__fool_devAppsConfig", devHost);
      msg.disconnect();
      //同步到同一浏览器其他窗口的sessionStorage
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      if (window.$vueEl) {
        window.$vueEl.$notify.closeAll();
      }
    },
    //获取在线数
    getOnlineTotal() {
      return new Promise((resolve, reject) => {
        system
          .getOnlineTotal()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default user;
