import { request } from '@/utils/request'
import Vue from 'vue'

/**
 * 获取用户信息
 * @returns 
 */
export const getCurrent = async()=>{
    const re = await request.get(import.meta.env.VITE_SYSMAN_URL+'/web/user/current')
    if(re.ok){
        let current = re.data;
        sessionStorage.setItem("current", JSON.stringify(current));
    }
}


/**
 * 获取Url中参数的值
 * @param {Stirng} name 参数的字段名
 */
 export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var result = window.location.search.substr(1).match(reg);
    if (result != null) return decodeURIComponent(result[2]);
    return null;
  }