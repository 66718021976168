import { Message } from 'element-ui'
import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import { bus } from 'vue-fant-base-plus'
import { timeout, BASE_URL } from './logRequest'
const PROD = import.meta.env.VITE_NODE_ENV === 'production'


// 创建axios实例
// var qs = require('qs'); // ES5
//挂起请求数组
let refreshSubscribers = []
/*刷新token的过期时间判断*/
function isRefreshTokenExpired() {
    let last = parseInt(localStorage.getItem('lastTokenTime')); // 这是在登陆时候或者刷新token时候保存的时间戳
    let now = new Date().getTime();
    let stamp = now - last;
    let minutes = parseInt(stamp / (1000 * 60));
    console.log('token已刷新'+minutes+'分钟',new Date().getHours()+':'+new Date().getMinutes()+':'+new Date().getSeconds());
    return minutes >= 25? true : false;
}
/*push所有请求到数组中*/
function subscribeTokenRefresh (cb) {
    refreshSubscribers.push(cb)
}
/*刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed (token) {
    refreshSubscribers.map(cb => cb(token))
}

//原生ajax的挂起请求数组
let ajaxArr = [];
/*push所有请求到数组中*/
function ajaxTokenRefresh (cb) {
    ajaxArr.push(cb)
}
/*刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onAjaxRrefreshed () {
    ajaxArr.map(cb => cb())
}

/**
 * 生产环境根据后台版本号替换请求地址
 * @param {string} url 
 * @param {Store} store 
 */
function replaceUrl(url, store){
    const version = JSON.parse(sessionStorage.getItem("version"));
    if(store.state.user.token && version && PROD){
        let header = "";
        if(url.indexOf("http://")!=-1){
            header = "http://";
            url = url.substr(7,url.length-1);
        }else if(url.indexOf("https://")!=-1){
            header = "https://";
            url = url.substr(8,url.length-1);
        }
        let fi = url.indexOf("/");
        let se = 0;
        if(fi > -1){
            se = url.indexOf("/",fi+1);
        }
        const mname = url.substr(fi+1,se-fi-1);
        const char1 = url.substr(0,se);
        const char2 = url.substr(se,url.length-1);
        if(version[mname]){
            url = header+char1+"/v"+version[mname]+char2;
        }
    }
    return url
}

/**
 * 刷新token操作，成功刷新后重新请求已挂起的请求操作
 * @param {Store} store 
 */
function refreshToken(store){
    store.commit('setIsRefreshing', true);
    store.dispatch('Refresh').then(re => {
        store.commit('setIsRefreshing', false);
        if (re.ok) {
            /*执行数组里的函数,重新发起被挂起的请求*/
            onRrefreshed(re.data)
            onAjaxRrefreshed()
            /*执行onRefreshed函数后清空数组中保存的请求*/
            refreshSubscribers = []
        }else{
            store.dispatch('LogOut');
        }
    }).catch(err=>{
        Message({
            message:"登录超时，请重新登录",
            type: 'error',
            duration: 5 * 1000,
            onClose:()=>{
                store.dispatch('LogOut');
            }
        });
    });
}

function hideLoading(){
    setTimeout(()=>{bus.hideLoading()},300)
}

export const request = axios.create({
    baseURL: BASE_URL, // api的base_url
    timeout,                  // 请求超时时间
});
// request拦截器
request.interceptors.request.use(function (config) {
    if (store.state.user.token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.Authorization = `Bearer ${store.state.user.token}`;
    }
    config.url = replaceUrl(config.url, store)
    /*判断token是否将要过期*/
    if(isRefreshTokenExpired() && store.state.user.token){
        /*判断是否正在刷新*/
        if(!store.state.isRefreshing) {
            refreshToken(store)
        }
        /*把请求(token)=>{....}都push到一个数组中*/
        let retry = new Promise((resolve, reject) => {
            /*(token) => {...}这个函数就是回调函数*/
            subscribeTokenRefresh((token) => {
                config.headers.Authorization = `Bearer ${store.state.user.token}`;
                resolve(config)
            })
        })
        return retry;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// respone拦截器
request.interceptors.response.use(
    response => {
        hideLoading()
        return response.data
    },
    error => {
        hideLoading()
        if(error.response.status == '401' ){
            if(store.state.user.token){
                /*判断是否正在刷新*/
                if(!store.state.isRefreshing) {
                    /*刷新token的请求挂起*/
                    subscribeTokenRefresh((token) => {
                        request(error.response.config);
                    });
                    refreshToken(store)
                }else{
                    //将token刷新过程中401的请求挂起
                    subscribeTokenRefresh((token) => {
                        request(error.response.config);
                    });
                }
            }else{
                store.dispatch('LogOut');
            }
        }else{
            Message({
                //message: error.message,
                message:"网络异常，请稍后重试",
                type: 'error',
                duration: 5 * 1000
            });
            return Promise.reject(error)
        }
    }
)
    
const ajax = (obj)=>{
    let opt = Object.assign(obj,{
        timeout,
        beforeRequest:(request)=>{
            let url = BASE_URL+obj.url;
            url = replaceUrl(url, store)
            request.url = url
            // request.open(obj.method, url, false) // 第三个参数 false 代表设置同步请求
            if (store.state.user.token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
                request.setHeaders = {"Authorization":`Bearer ${store.state.user.token}`};
            }
            /*判断token是否将要过期*/
            if(isRefreshTokenExpired() && store.state.user.token){
                /*判断是否正在刷新*/
                if(!store.state.isRefreshing) {
                    refreshToken(store)
                }
                ajaxTokenRefresh(() => {
                    ajax(obj)
                });
                return false;
            }else{
                return request;
            }
        },
        beforeResponse:(res)=>{
            hideLoading()
            if (res.status !== '200' && res.status !== 200) {
                if(res.status == '404'){
                    // window.location.href="/status/404";
                }else if(res.status == '401'){
                    ajaxTokenRefresh(() => {
                        ajax(obj)
                    });
                    refreshToken(store)
                    return false;
                }else if(res.status == '500'){
                    // window.location.href="/status/500";
                    return false;
                }else if(res.status == '504'){
                    // window.location.href="/status/504";
                    return false;
                }
            } else {
                return true;
            }
        }
    });
    Vue.ajax(obj);
}
const requestPlugin = {}

requestPlugin.install = ()=>{
    Vue.prototype.$http = request;
    Vue.prototype.$ajax = ajax;
    const downloadFile = (url)=>{
        url = BASE_URL+url;
        url = replaceUrl(url, store)
        if(url.indexOf('?')!= -1){
            url+=`&token=${store.state.user.token}`;
        }else{
            url+=`?token=${store.state.user.token}`;
        }
        window.location.href=url;
    }
    Vue.prototype.$downloadFile = downloadFile;
}

export default requestPlugin;
