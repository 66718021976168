<style lang="scss">

</style>
<template>
  <div id="app" class="page-box" v-cloak>
    <router-view ref="inRouter"></router-view>
  </div>
</template>
<script>
import msgAction from '@/components/msgAction.vue';
import { bus } from 'vue-fant-base-plus'
import Vue from 'vue'
export default {
  data () {
    return {
        tabEvent:{}
    }
  },
  methods:{
  },
  updated(){
      //修复切换tab页的时候，下拉框不消失的问题
      this.$fromArray(this.$getEl(".el-picker-panel")).forEach(function(item){
          if(!item.isHidden())item.hide();
      });
      this.$fromArray(this.$getEl(".el-cascader-menus")).forEach(function(item){
          if(!item.isHidden())item.hide();
      });
  },
  created(){
  },
  mounted(){
    bus.$on('Notification', (data)=>{
      const createElement = this.$createElement
      createElement(
          'div',
          {
              'class':{
                  'fant-globalmsg':true
              }
          },
          [
              createElement(
                  msgAction,
                  {
                      props:{
                          data:data
                      }
                  }
              )
          ]
      );
      this.$notify({
        title:data.title,
        dangerouslyUseHTMLString:true,
        iconClass:'el-icon-info color-primary',
        message:msg,
        position: 'bottom-right',
        duration: 5000
      });
    })
    
  }
}
</script>

