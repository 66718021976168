import Vue from 'vue'
import Router from 'vue-router'

// const originalReplace = Router.prototype.replace
// Router.prototype.replace = function replace(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//     return originalReplace.call(this, location).catch(err => err)
// }
// // 解决vue-router升级到3.1，push方法改成promise方式后，旧写法报错的问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Router)
function createRouter () {
    return new Router({
        mode: 'history',
        base: import.meta.env.BASE_URL,
        routes: [
            {
                path: "/",
                name: "layout",
                component: () => import('@/views/layout/layout.vue'),
                children: [
                    { //消息
                        path: '/messageCenter',
                        name: 'messageCenter',
                        component:  () => import('@/views/message/message.vue'),
                    },
                    { //用户设置
                      path: '/userSetting',
                      name: 'userSetting',
                      component:  () => import('@/views/platform/userSetting/index.vue'),
                    },
                    {
                        path: 'instatus/:code',
                        name: 'instatus',
                        component: () => import('@/views/layout/status.vue'),
                        props: true
                    },
                ],
            },
            {
                path: '/frame',
                name: 'frame',
                component: () => import('@/views/frame/frame.vue')
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/layout/login.vue')
            },
            {
                path: '/transit',
                name: 'transit',
                component: () => import('@/views/layout/transit.vue')
            },
            {
                path:'/',
                redirect: '/transit'
            },
            // {
            //     path: '/register',
            //     name: 'register',
            //     component: resolve => require(['../views/layout/register.vue'], resolve)
            // },
            // {
            //     path: '/retrievePassword',
            //     name: 'retrievePassword',
            //     component: resolve => require(['../views/layout/retrievePassword.vue'], resolve)
            // },
            {
                path: '/status/:code',
                name: 'status',
                component: () => import('@/views/layout/status.vue'),
                props: true
            },
            // { path: '*', component: () => import('@/views/layout/subapp.vue') }
        ]
    });
}

let router = createRouter();

/**
 * 重置路由
 */
export function restRouter() {
    router.matcher = createRouter().matcher;
}

export default router