export const connected = state => state.message.connected

export const unreadMsgCount = state => state.message.unreadMsgCount

export const messages = state => state.message.messages

export const is_collapse = state => state.menu.is_collapse

export const menuTree = state => state.menu.menuTree

export const menuItem = state => state.menu.item

export const menuSelectItem = state => state.menu.selectItem

export const breadcrumb = state => state.menu.breadcrumb

export const current = state => state.user.user

export const routeHistoryStack = state => state.menu.routeHistoryStack