import { getCurrent } from '@/utils/tools'
import { request } from '@/utils/request'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'
import { registerApps } from '@/apps'
import devApps from './devApps'
import { BASE_URL, BASEMSG_URL } from '@/utils/logRequest'
import { bus } from 'vue-fant-base-plus'
const prod = import.meta.env.VITE_NODE_ENV === 'production'
/* 准生产环境，方便调试生产问题，并且可作为前端子项目开发环境的外框使用 */
export const staging = import.meta.env.MODE === 'staging'
console.log(import.meta.env.NODE_ENV,import.meta.env.VITE_NODE_ENV)
/**
 * 根据菜单加载路由，并且返回微应用列表
 * @param {Router} router 
 * @param {array} services 
 * @returns 
 */
export const loadRoutesAndGetApps = (services, myrouter)=>{
    const apps = []
    const apps_ = []
    /* 作为前端子项目开发环境的外框使用时，提供sessionStorage的__fool_devAppsConfig配置子项目的加载地址 */
    let devAppsConfig = {}
    if(staging && sessionStorage.__fool_devAppsConfig){
        try{
            devAppsConfig = JSON.parse(sessionStorage.__fool_devAppsConfig)
        }catch(err){
            devAppsConfig = {}
        }
    }
    for(let i in services){
        const item = services[i]
        let domain = typeof item.domain == 'string' && item.domain[item.domain.length-1]!=='/'?(item.domain+'/'):item.domain
        let entry = staging && devAppsConfig[item.moduleCode]&&devAppsConfig[item.moduleCode]?devAppsConfig[item.moduleCode]:(location.origin + domain);
        entry = entry + (entry.indexOf('?')!=-1?'&r=':'?r=') + Math.random()
        const app = prod?{
            name: item.moduleCode,
            entry,
            container: '#subapp-view',
            activeRule: item.domain
        }:devApps[item.moduleCode]
        if(app){
            staging&&!devAppsConfig[app.name]?(devAppsConfig[app.name] = app.entry):null
            apps_.push(app)
            apps.push({
                ...app,
                props: {
                    /* 每个微应用的菜单，此处不能传实例，因为刷新后经过JSON转换处理后实例会掉失 */
                    menus: item.children,
                }
            })
            myrouter.addRoute('layout',{
                path: item.domain.replace(/\//g,'')+'*',
                name: item.moduleCode,
                component: () => import('@/views/layout/subapp.vue'),
                // children:[
                //     {
                //         path:'*',
                //         component: () => import('@/views/layout/subapp.vue'),
                //     }
                // ]
            })
        }
        staging?(sessionStorage.setItem('__fool_devAppsConfig', JSON.stringify(devAppsConfig))):null
    }
    store.commit('appStore/SET_APPINFO', apps_)
    sessionStorage.setItem('__fool_microApps', JSON.stringify(apps))
    return apps
}


export default ()=>{
    return new Promise(async(resolve)=>{
        /**
         * 默认跳转的微应用，通常是微应用列表的首个应用
         */
        // let defaultApp = ''
        /**
         * 记录每个页面对应的展示模块code
         */
        let showModuleCodes = {}
        /**
         * 登陆后刷新直接获取本地记录的菜单和权限数据
         */
        if (sessionStorage.getItem("token")) {
            /* 记录权限 */
            store.commit('SET_NAME', window.sessionStorage.getItem("name"));
            store.commit('SET_TOKEN', window.sessionStorage.getItem("token"));
            store.commit('SET_REFTOKEN', window.sessionStorage.getItem("refreshToken"));
        }
        if (sessionStorage.getItem("token") && !sessionStorage.getItem("auth")) {
            /* 如果成功登陆后，加载权限和菜单 */
            const perRes = await request.get("/auth/permission")
            if (perRes.ok) {
                // services = perRes.data
                let authArr = [];
                let versionArr = {};
                perRes.data.forEach(item => {
                    const moduleCode = item.moduleCode;
                    versionArr[moduleCode] = item.version;
                    if(item.auths){
                        item.auths.forEach(auth => {
                            showModuleCodes[auth.resourceCode] = moduleCode
                            const authCode = moduleCode + "." + auth.resourceCode + "." + auth.code;
                            authArr.push(authCode);
                        });
                    }
                });
                /* 方便测试，临时添加的文件服务，待删除 */
                versionArr['file'] = 2
                let permissions = JSON.stringify(authArr);
                sessionStorage.setItem("auth", permissions);
                store.commit('setVersion', versionArr)
                /* 把权限数据加载到fant插件里面 */
                Vue.config.fant.authList = authArr;
            }
            //获取企业所有人员数据，并存入sessionStorage
            const basedataRes = await request.get(Vue.prototype.$SYSMAN_URL+"/web/staff/firm/list/basedata")
            if (basedataRes) {
                let employees = {};
                basedataRes.forEach(item => {
                    employees[item.id] = item;
                });
                sessionStorage.setItem("employees", JSON.stringify(employees));
            }

            if(!sessionStorage.getItem("current")){
                await getCurrent()
            }
              /* 是否运营端 */
              const vuex = JSON.parse(sessionStorage.getItem("vuex"))
              if(!vuex.user.isPlatformOperate){
                    const typeRes = await request.get(Vue.prototype.$BASEDATA_URL+'/web/settingType/page')
                    try{
                        if(typeRes.ok){
                            let d = {}
                            typeRes.data.forEach(item=>{
                                d[item.code] = item
                            })
                            store.commit('setConfigTypes', typeRes)
                        }
                    }catch(err){
        
                    }
                }
        }
        if(!sessionStorage.getItem("menu")){
            const firmRes = await request.get(Vue.prototype.$SYSMAN_URL+"/web/firm")
            if (firmRes.ok){
                sessionStorage.setItem("firm", JSON.stringify(firmRes.data));
                //不是学校运营员
                if(!firmRes.data.operator){
                    const firmBase = await request.get(Vue.prototype.$BASEDATA_URL+"/web/operating/school/info/"+firmRes.data.id)
                    if (firmBase.ok){
                        const firmBaseData = firmBase.data
                        sessionStorage.setItem("firmBaseData", JSON.stringify(firmBaseData));
                    }
                }
            }
            if (firmRes.ok && firmRes.data.operator) {
                Vue.prototype.$operator = true
                sessionStorage.setItem("operator", Vue.prototype.$operator);
            }else{
                Vue.prototype.$operator = false
                sessionStorage.setItem("operator", Vue.prototype.$operator);
            }
            const current = JSON.parse(sessionStorage.getItem('current'))
            const menuRes = await request.get(`/auth/menu`)
            if(menuRes.ok){
                let menus = menuRes.data;
                // menus[0]={
                //     children:[],
                //     name: "系统管理",
                // }
                // menus[0].children.push({
                //     children: [],
                //     code: "web.role",
                //     domain: "/sysman/",
                //     icon: "",
                //     id: "1bb7a715-7a7e-48db-a94f-ba2a2f3285a9",
                //     moduleCode: "sysman",
                //     moduleVersion: 2,
                //     name: "角色",
                //     ordered: 3,
                //     parentId: "32367d4b-c2cb-11e8-80f6-509a4c63dd82",
                //     type: "resource",
                // })
                // menus[0].children.push({
                //     children: [],
                //     code: "web.user",
                //     domain: "/sysman/",
                //     icon: "",
                //     id: "1bb7a715-7a7e-48db-a94f-ba2a2f3285a9",
                //     moduleCode: "sysman",
                //     moduleVersion: 2,
                //     name: "用户",
                //     ordered: 3,
                //     parentId: "32367d4b-c2cb-11e8-80f6-509a4c63dd82",
                //     type: "resource",
                // })
                sessionStorage.setItem("menu", JSON.stringify(menus));
                const subapps = {} 
                const menusByCode = {}
                const menusByMoudleCode = {}
                const getMenusJson = (menus)=>{
                    menus.forEach(item=>{
                        if(item.domain && item.moduleCode && (!item.children || item.children.length == 0)){
                            item.showModuleCode = showModuleCodes[item.code]
                            menusByMoudleCode[item.moduleCode] = menusByMoudleCode[item.moduleCode] || {}
                            menusByMoudleCode[item.moduleCode][item.code] = item
                            subapps[item.moduleCode] = subapps[item.moduleCode] || {moduleCode:item.moduleCode, domain:item.domain, children:[]}
                            subapps[item.moduleCode].children.push(item)
                            menusByCode[item.code] = item
                        }
                        if(item.children && item.children.length>0){
                            getMenusJson(item.children)
                        }
                    })
                }
                getMenusJson(menus)
                /* 子应用的资源集合，以便注册子应用 */
                sessionStorage.setItem("__fool_subapps", JSON.stringify(subapps));
                /* 通过code索引到对应的路由信息，包括属于哪个微应用这些信息 */
                sessionStorage.setItem("__fool_menusbycode", JSON.stringify(menusByCode));
                /* 子应用通过code索引到对应的路由信息 */
                sessionStorage.setItem("__fool_menusbymoudlecode", JSON.stringify(menusByMoudleCode));
                /* 无特殊情况，路由类型微应用渲染的container都是layout页面的#subapp-view */
                const apps = loadRoutesAndGetApps(subapps, router)

                /* 根据菜单接口动态注册微应用 */
                registerApps(apps)
                
                // defaultApp = menus[0]?.children[0]?.children[0]?(menus[0]?.children[0]?.children[0]?.domain+menus[0]?.children[0]?.children[0]?.code):(menus[0]?.children[0]?.domain+menus[0]?.children[0]?.code)
                // const route = menus[0]?.children[0]?.children[0]?menus[0]?.children[0]?.children[0]:menus[0]?.children[0]
                // defaultApp = route
            }
        }
        /**
         * 加载路由
         */
        if (sessionStorage.getItem("menu")) {
            const menu = JSON.parse(sessionStorage.getItem("menu"));
            const version = JSON.parse(sessionStorage.getItem("version"));
            /* 仓库记录版本信息 */
            store.commit('setVersion', version)
            // store.dispatch("addMenu", menu);
            store.dispatch("setMenu", menu);
        }

        
        loadConfig()
        resolve()
    })
}

export const loadConfig = ()=>{
    /* 主模块服务前缀设置在request里面 */
    Vue.prototype.$BASE_URL = BASE_URL
    /* 系统管理模块服务前缀 */
    Vue.prototype.$SYSMAN_URL = import.meta.env.VITE_SYSMAN_URL
    /* oa模块服务前缀 */
    Vue.prototype.$OA_URL = import.meta.env.VITE_OA_URL
    /* 基础模块服务前缀 */
    Vue.prototype.$BASEDATA_URL = import.meta.env.VITE_BASEDATA_URL
    /* 校讯通模块服务前缀 */
    Vue.prototype.$XIAOXUNTONG_URL = import.meta.env.VITE_XIAOXUNTONG_URL
    /**
     * 前缀配置
     */
     if (sessionStorage.getItem("menu")) {
        const version = JSON.parse(sessionStorage.getItem("version"));
        /* 消息api前缀 */
        Vue.prototype.$FULL_MESSAGE_URL = BASEMSG_URL + import.meta.env.VITE_MESSAGE_URL + (prod?('/v' + version['message']):'')
        Vue.prototype.$MESSAGE_URL = import.meta.env.VITE_MESSAGE_URL
        bus.$FULL_MESSAGE_URL = Vue.prototype.$FULL_MESSAGE_URL
        /* 文件模块服务前缀 */
        Vue.prototype.$FILE_URL = import.meta.env.VITE_FILE_URL
        /* 文件模块服务全前缀 */
        Vue.prototype.$FULL_FILE_URL = BASE_URL + import.meta.env.VITE_FILE_URL + (prod?('/v' + version['file']):'')
    }

    /* 是否运营人员 */
    if(sessionStorage.getItem("operator")){
        Vue.prototype.$operator = sessionStorage.getItem("operator") == 'true'?true:false
    }

    // if(sessionStorage.getItem('token')){
    //     /* 必须在loadConfig之后 */
    //     store.dispatch('Subscribe').then(()=>{
    //         store.dispatch('OnMessage');
    //     })
    // }
}