import Message from './message';
//注入组件
export function mixinApp (Vue) {
    const MSG__ = new Message()
    Vue.mixin({
        data(){
            return {
                MSG__
            }
        },
    	methods:{
            exportExcel(url,data){
                return new Promise(resolve=>{
                    this.$http({
                        url:url,
                        method:'post',
                        responseType:'blob',
                        data
                    }).then((re)=>{
                        let blob = new Blob([re], { type: "application/vnd.ms-excel" })
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                        resolve()
                    }).catch((err)=>{
                        this.$message({type:'error',message:MSG__.ERROR})
                    })
                })
            },
            formatNumber(val){
                return Number(val).toFixed(2).replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,');
            },
			formatNum(val){
				return Number(val).toFixed(0).replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,');
			},
			// 数字取整百数
			Numberhundred(num){
			  let str=String(num);
			  let val=str.length > 2 ? str.substring(0,str.length-2) : 0;
			  return num % 100 > 0 ? (+val+1)*100 : +val*100;
			},
            formatDate(val,format){//全局统一formatDate
                if(val){
                  val =(val+'').replace(/\-/g, "/");
                }
                if(format){
                  return new Date(val).format(format);
                }else{
                  return new Date(val).format("yyyy年MM月dd日 hh:mm");
                }
          
            },
            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";
             
                var uuid = s.join("");
                return uuid;
            },
			uniqBy(arr,key){
			    return [...new Map(arr.map(item=>[item[key],item])).values()]
			},
			getLastDay(type,d,curQuarterNum){ //类型  时间  当前季度
				let cloneTime=new Date(d);
				let year=cloneTime.getFullYear();
				let date=cloneTime.getDate();
				let month=cloneTime.getMonth();
				let day=cloneTime.getDay() || 7;
				if(type == 'week'){
					return {
						startTime:new Date(new Date(cloneTime).setDate(date-(day-1))).format('yyyy-MM-dd'),
						endTime:new Date(new Date(cloneTime).setDate(date+7-day)).format('yyyy-MM-dd')
					}
				}else if(type == 'month'){
					return {
						startTime:new Date(new Date(cloneTime)).format('yyyy-MM-01'),
						endTime:new Date(year,month+1,0).format('yyyy-MM-dd')
					}
				}else if(type == 'year'){
					return {
						startTime:new Date(new Date(cloneTime)).format('yyyy-01-01'),
						endTime:new Date(year,month+1,0).format('yyyy-12-31')
					}
				}else if(type == 'day'){
					return {
						startTime:cloneTime.format('yyyy-MM-dd'),
						endTime:cloneTime.format('yyyy-MM-dd')
					}
				}else if(type == 'quarter'){
					let curQuarter=curQuarterNum || Math.ceil((month+1)/3);
					let endMonth=curQuarter * 3;
					let startMonth=endMonth-2;
					return {
						startTime:new Date(year,startMonth-1,1).format('yyyy-MM-01'),
						endTime:new Date(year,endMonth,0).format('yyyy-MM-dd')
					}					
				}
			},
    		// 导出  method传输方法,url地址，params参数，filename导出的文件名
    		downloadFile(method="get",url,params,filename,callback,type='application/vnd.ms-excel',responseType='arraybuffer'){
				// this.showIndexLoadingWin('正在操作中，请勿关闭或刷新浏览器');
                this.$http({url:url,method: method,data:params,responseType: responseType})
                // this.$http[method](url,params,{responseType:responseType})
    			.then(res=>{
                    if(res){
                        let blob = new Blob([res], { type: type })  
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        // this.closeIndexLoadingWin();
                        if(typeof callback == "function"){
                            callback();
                        }
                    }else{
                        this.$message({
                            showClose:true,
                            message:res.message,
                            type:'error'
                        });
						// this.closeIndexLoadingWin();
                    }
                }).catch(err=>{
                    // this.closeIndexLoadingWin();
                })
            },
            downloadTemplate(url,fileId,name,downloadParams={},method='get'){	
				// this.showIndexLoadingWin('正在到操作中,请勿关闭或刷新浏览器');
				this.$http({
					url:url,
					method: method,
					data:downloadParams,
					responseType:'arraybuffer',
				}).then(res=>{
				    if(res){
				        let blob = new Blob([res], { type: 'application/vnd.ms-excel'})  
				        let link = document.createElement('a')
				        link.href = window.URL.createObjectURL(blob);
				        link.download =name;
				        document.body.appendChild(link);
				        link.click();
				        document.body.removeChild(link);
						// this.closeIndexLoadingWin();
				    }
				}).catch((err) => {
					// this.closeIndexLoadingWin();
				    this.$message({showClose:true, msg: "服务器繁忙，请稍后再试！", type: "error"});
				}); 
            },
    		// 删除  row数据,method方法，successCallback成功回调
    		deleteCurrent(method='delete',url,successCallback,text="删除"){
    			this.$confirm({
                    msg:`确定要${text}吗？`,
                    fn:bool=>{  
                        if(bool){
                            this.$http[method](url).then(res=>{
                                if(res.ok){
                                    this.$message({
                                        showClose:true,
                                        msg:`${text}成功`,
                                        type:'success'
                                    })
                                    successCallback && successCallback(res);
                                }else{
                                    this.$message({
                                        showClose:true,
                                        message:res.message,
                                        type:'error'
                                    });
                                }
                            }).catch((err) => {
                                this.$message({showClose:true, msg: "服务器繁忙，请稍后再试！", type: "error"});
                            });                           
                        }
                    }
                })
            },
            customReq(method='post',url,params={},successCallback,errorCallback){
                this.$http[method](url,params).then(res=>{
                    // // console.log(res)
                    if(res.ok){
                        successCallback && successCallback(res);
                    }else{
                        this.$message({
                            showClose:true,
                            message:res.message,
                            type:'error'
                        });
                        errorCallback && errorCallback();
                    }
                })
            },
			formatSeconds (time) {
			  return this.numAddPrefix(Math.floor(time / 60)) + ':' + this.numAddPrefix(time % 60)
			},
			numAddPrefix(val){
				return val < 10 ? '0'+val : val;
			},
			monthAllWeek(date){
				let year=date.getFullYear();
				let month=date.getMonth();
				let day=new Date(year,month,1).getDay() || 7;
				let allDay=new Date(year,month+1,0).getDate();
				return Math.ceil((allDay+day-1)/7);
			},           
            // 数组根据key排序
    		sortByKey(array,key){
                return array.sort(function(a,b){
                  var x=a[key];
                  var y=b[key];
                  return ((x<y)?-1:((x>y)?1:0));
                });
            },
            // 去重
            duplicate(arr,type){
                if(arr.length == 0){
                    return arr;
                }else{
                    if(type){
                        var obj = {}
                        var newArr = arr.reduce((cur,next) => {						
                            let keyName = next[type];				
                            obj[keyName] ? "" : obj[keyName] = true && cur.push(next);
                            return cur;
                        },[])
                        return newArr;
                    }else{
                        return Array.from(new Set(arr));
                    }
                }
            },
            currentWeek(date){
				let base=0;
				let firstDay=new Date(new Date(date).setDate(1)).getDay();
				if(firstDay == 0){  
				   base=1;
				}
                let weekday = date.getDay() || 7;
                let day = date.getDate();
                return Math.ceil((day + 6 - weekday || 1) / 7)+base;
            },
            copyObj(obj) {
                var result = Array.isArray(obj) ? [] : {};
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (typeof obj[key] === 'object') {
                            result[key] = copyObj(obj[key]);   
                        } else {
                            result[key] = obj[key];
                        }
                    }
                }
                return result;
            },
			formatTime(time){
			    let val=time.split(':');
			    var hour=time.split(':')[0];
			    var minutes=time.split(':')[1];
			    if(val.length == 2){
			        return {
			            hour,
			            minutes
			        };
			    }
			    return null;
			},
            convertToChinaNum(num) {
                var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
                var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿');//可继续追加更高位转换值
                if(!num || isNaN(num)){
                    return "零";
                }
                var english = num.toString().split("")
                var result = "";
                for (var i = 0; i < english.length; i++) {
                    var des_i = english.length - 1 - i;//倒序排列设值
                    result = arr2[i] + result;
                    var arr1_index = english[des_i];
                    result = arr1[arr1_index] + result;
                }
                //将【零千、零百】换成【零】 【十零】换成【十】
                result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
                //合并中间多个零为一个零
                result = result.replace(/零+/g, '零');
                //将【零亿】换成【亿】【零万】换成【万】
                result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
                //将【亿万】换成【亿】
                result = result.replace(/亿万/g, '亿');
                //移除末尾的零
                result = result.replace(/零+$/, '')
                //将【零一十】换成【零十】
                //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
                //将【一十】换成【十】
                result = result.replace(/^一十/g, '十');
                return result;
            },
            //格式yyyy-MM-dd
            dateWeekday(date){
                var weekArray = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
                var week = weekArray[new Date(date).getDay()];
                return week;
            },
			formatFileSize: function (size) {
				Number(size);
				if (size < 1024) {
					return size + 'B';
				}
				if (size >= 1024 && size < 1048576) {
					return (size / 1024).toFixed(1) + 'KB';
				}
				if (size >= 1048576 && size < 1073741824) {
					return (size / 1048576).toFixed(1) + 'M';
				}
				if (size > 1073741824 && size < 1099511627776) {
					return (size / 1073741824).toFixed(1) + 'Gb';
				}
				if (size > 1099511627776) {
					return (size / 1099511627776).toFixed(1) + 'Tb';
				}
            },
            //格式yyyy-MM-dd true代表当前时间大于日期时间
            dateCompare(date){
				let d1 = new Date();
				let d2 = new Date(date.replace(/\-/g, "/"));
				return d1.getTime()>d2.getTime();
            },
            //格式yyyy-MM-dd true表示结束时间大于开始时间
            dateBtCompare(dateStar,dateEnd){
                let start = new Date(dateStar.replace(/\-/g, "/")).format('yyyy-MM-dd 00:00:00');
				let endDate = new Date(dateEnd.replace(/\-/g, "/")).format('yyyy-MM-dd 23:59:59');
				let d1 = new Date(start).getTime();
				let d2 = new Date(endDate).getTime();
				return d1<d2;
            },
            //自定义加法
            accAdd(arg1, arg2) {
                var r1, r2, m, c;
                try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
                try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
                c = Math.abs(r1 - r2);
                m = Math.pow(10, Math.max(r1, r2))
                if (c > 0) {
                    var cm = Math.pow(10, c);
                    if (r1 > r2) {
                        arg1 = Number(arg1.toString().replace(".", ""));
                        arg2 = Number(arg2.toString().replace(".", "")) * cm;
                    }
                    else {
                        arg1 = Number(arg1.toString().replace(".", "")) * cm;
                        arg2 = Number(arg2.toString().replace(".", ""));
                    }
                }
                else {
                    arg1 = Number(arg1.toString().replace(".", ""));
                    arg2 = Number(arg2.toString().replace(".", ""));
                }
                return (arg1 + arg2) / m
            },
            //自定义减法
            accSub(arg1,arg2){
                var r1,r2,m,n;
                try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
                try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
                m=Math.pow(10,Math.max(r1,r2));
                //last modify by deeka
                //动态控制精度长度
                n=(r1>=r2)?r1:r2;
                return ((arg1*m-arg2*m)/m).toFixed(n);
            },
            dataZoom(isShow) {
                return isShow ? [{
                    show: true,
                    height: 15,
                    xAxisIndex: [0],
                    bottom: 20,
                    "start": 10,
                    "end": 80,
                    handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                    handleSize: '100%',
                    handleStyle: {
                        color: "rgba(22,173,242,1)",
                    },
                    textStyle: {
                        color: "#9d9d9d",
                    },
                    fillerColor: "rgba(22,173,242,0.2)",
                    borderColor: "#16ADF2",
            
                }, {
                    type: "inside",
                    show: true,
                    height: 15,
                    start: 1,
                    end: 35
                }] : []
            },
            getSimpleText(html){
				var re1 = new RegExp("<.+?>","g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
				var msg = html.replace(re1,'');//执行替换成空字符
				return msg;
            },
            /**
             * 获取当前月份的第一天和最后一天
             * @returns {string}  例如 2019-09-01~2019-09-30
             */
            getMonthFirstLastDay() {
                var myDate = new Date();
                var currentMonth = myDate.getMonth();
                var firstDay = new Date(myDate.getFullYear(), currentMonth, 1)
                var lastDay = new Date(firstDay.getFullYear(), currentMonth + 1, 0);
                firstDay = firstDay.format("yyyy-MM-dd");
                lastDay = lastDay.format("yyyy-MM-dd");
                return [firstDay,lastDay]
            },
    	}
    })
}

// table复选框单选

// .main-table >>> thead .el-table-column--selection .cell {
//     display: none;
// }
// tableRowChange(selection){
//     if (selection.length > 1) {
//         this.$refs.firmTable.clearSelection();                    
//         this.$refs.firmTable.$refs.elTable.toggleRowSelection(selection.pop());
//     }
//     this.$refs.firmTable.mydata.filter(item=>item !== selection[0]).map(item=>item.$checked=false);
//     this.selection=selection;
    
// }