// import Map from "@/router/routeMap"

const menu = {
  state: {
    menuTree: [], //头部所有菜单
    item: [], //头部的子菜单
    selectItem: {}, //头部的子菜单对应选中的子菜单
    breadcrumb: [], //面包屑
    isLoadRoutes: false,
    is_collapse: false, // 折叠状态
    routeHistoryStack: [], //路由历史栈
  },
  mutations: {
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_SELECT_ITEM(state, item) {
      state.selectItem = item;
    },
    SET_BREAD(state, item) {
      if (Array.isArray(item)) {
        state.breadcrumb = item;
        return;
      }
      item.add
        ? state.breadcrumb.push(item.data)
        : (state.breadcrumb = [item.data]);
    },
    SET_MEAN(state, menuItems) {
      state.item = [];
      state.menuTree = [];
      state.breadcrumb = [];
      state.isLoadRoutes = false;
      state.is_collapse = false;
      if (menuItems.length === 0) {
        state.menuTree = [];
      } else {
        generateMenuItems(state.menuTree, menuItems);
        state.item = menuItems[0];
      }
    },
    ADD_MEAN(state, menuItems) {
      if (menuItems.length === 0) {
        state.items = [];
        state.menuTree = [];
      } else {
        generateMenuItems(state.menuTree, menuItems);
      }
    },
    LOAD_ROUTES(state) {
      state.isLoadRoutes = !state.isLoadRoutes;
    },
    SET_COLLAPSE_STATUS(state, data) {
      state.is_collapse = data;
    },
    SET_ROUTE_NAME_CHAIN(state, data) {
      state.routeHistoryStack = data;
    },
  },
  actions: {
    setItem: ({ commit }, item) => {
      commit("SET_ITEM", item);
    },
    setSelectItem: ({ commit }, item) => {
      commit("SET_SELECT_ITEM", item);
    },
    setBread: ({ commit }, item) => {
      commit("SET_BREAD", item);
    },
    setMenu: ({ commit }, menuItems) => {
      commit("SET_MEAN", menuItems);
    },
    addMenu: ({ commit }, menuItems) => {
      commit("ADD_MEAN", menuItems);
    },
    loadRoutes: ({ commit }) => {
      commit("LOAD_ROUTES");
    },
    setCollapseStatus({ commit }, data) {
      commit("SET_COLLAPSE_STATUS", data);
    },
    setRouteHistoryStack({ commit }, data) {
      commit("SET_ROUTE_NAME_CHAIN", data);
    },
  },
  getters: {},
};
function generateMenuItems(menuTree, menuItems) {
  menuItems.forEach((item) => {
    menuTree.push(item);
  });
  // 过滤人脸关闭时不开放的菜单
  const vuex = JSON.parse(sessionStorage.getItem("vuex"));
  let useDevice = "";
  if (vuex.user.schoolInfo) {
    useDevice = vuex.user.schoolInfo.useDevice;
  }
  if (useDevice) {
    return;
  }
  menuTree = filterMenuTree(menuTree);
  function filterMenuTree(arr) {
    let useDeviceData = ["basic.device", "basic.faceMent", "edu.accessControl"];
    return arr.filter((item) => {
      if (item.children) {
        item.children = filterMenuTree(item.children);
      }
      return !useDeviceData.includes(item.code);
    });
  }
}

export default menu;
