import { registerMicroApps, start } from 'qiankun';
import store from '@/store'
import request from '@/utils/request'

export const registerApps = (apps)=>{
  apps.forEach(item=>{
    /* 主应用store用于初始化微应用的$fts实例，以便于多应用间的通讯 */
    item.props['store'] = store
    item.props['request'] = request
  })
  /**
   * 配置微应用
   */
  registerMicroApps(apps,
    {
      beforeLoad: [
        app => {
          console.log("[主应用] before load %c%s", "color: green;", app.name);
        }
      ],
      beforeMount: [
        app => {
          console.log("[主应用] before mount %c%s", "color: green;", app.name);
        }
      ],
      afterMount: [
        app => {
          console.log("[主应用] afterMount mount %c%s","color: green;",app.name);
        }
      ],
      afterUnmount: [
        app => {
          console.log("[主应用] after unmount %c%s", "color: green;", app.name);
        }
      ]
    }
  );

  // 启动 qiankun
  start();
}

