export function setName(name) {
  return window.sessionStorage.setItem("name", name);
}
export function getName() {
  return window.sessionStorage.getItem("name");
}
export function setToken(token) {
  return window.sessionStorage.setItem("token", token);
}
export function getToken() {
  return window.sessionStorage.getItem("token");
}
export function setRefToken(token) {
    return window.sessionStorage.setItem("refreshToken", token);
}
export function getRefToken() {
    return window.sessionStorage.getItem("refreshToken");
}
