<style scoped lang="scss">
    .__img{cursor: pointer;}
    .img-error{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width:100%;
        height:100%;
        i{
            font-size:30px;
            color:#666;
        }
    }
</style>
<template>
    <span style="display:inline-block;overflow:hidden;" class="clearfix" :style="{'border-radius':isCircular?'50%':'0',width:curWidth, height:curHeight, cursor:preview && !error?'pointer':''}">
        <img
            v-show="showImage && (avatar || (!avatar && !error))"
            ref="image"
            style="width:100%;height:100%;"
            :src="imgSrc" 
            :style="{'object-fit':fit}"
            :preview="preview"
            :preview-text="previewText"
            @error="onError"
            @load="load"
        />
        <div class="img-error" v-if="showImage && !avatar && error">
            <i class="el-icon-picture-outline" :style="{'font-size':curWidth.indexOf('%')==-1?`calc(${curWidth} / 2)`:''}"></i>
        </div>
        <span v-if="!showImage" @click="open"><slot></slot></span>
    </span>
</template>

<script>
import defaultHeader from '@/assets/images/defaultHeader.png'
export default {
    props:{
        fit:{
            /* fill / contain / cover / none / scale-down */
            type:String,
            default:'contain'
        },
        width:{
            type:[String, Number],
            default:'100%'
        },
        height:{
            type:[String, Number],
            default:'100%'
        },
        avatar:{
            type:Boolean,
            default:false
        },
        isCircular:{
            type:Boolean,
            default:false
        },
        imgId:{
            type:String,
            default:''
        },
        staffId:{
            type:String,
            default:''
        },
        type:{type:String,default(){return 'firm'}},
        previewText:{type:String},
        preview:{type:[Boolean, String, Number],default(){return false;}},
        showImage:{type:Boolean,default(){return true;}},
        files:{type:Array,default(){return []}},
        firmId:{type:String}
    },
    computed:{
        imgSrc(){
            this.error = false
            this.$nextTick(()=>{
                this.$refs.image.removeAttribute('__error')
            })
            return this.relateId?this.imgPrefix+this.relateId+'?token='+sessionStorage.getItem("token"):'';  
        },
        imgPrefix(){
            if(this.type == 'user'){
                return `${this.$FULL_FILE_URL}/web/user/file/`;        
            }else{
                return `${this.$FULL_FILE_URL}/web/firm/file/`+(this.firmId?(this.firmId+'/'):'');   
            }
        },
        relateId(){
            let relateId = ''
            if(this.staffId){
                let staffs = this.firmId?this.firmStaffs[this.firmId]:this.staffs;
                if(staffs[this.staffId]){
                    relateId=staffs[this.staffId].headerImgId;
                }
            }
            if(this.imgId){
                relateId=this.imgId;
            }
            return relateId
        },
        curWidth(){
            return typeof this.width == 'number'?(this.width+'px'):this.width
        },
        curHeight(){
            return typeof this.height == 'number'?(this.height+'px'):this.height
        }
    },
    watch:{
        firmId:{
            handler:function(val,oldVal) {
                if(!val) return;
                if(!window.localStorage.getItem("firmStaffs"+val) && this.$store.state.user.token){ //列表头像取第一个请求接口
                    this.$http.get(this.$SYSMAN_URL + '/web/staff/department/group/basedata/'+this.firmId).then(re => {
                        if (re.ok) {
                            let staffs = {}
                            this.loopFn(re.data,staffs);
                            this.firmStaffs[val]=staffs;
                            localStorage.setItem("firmStaffs"+val,JSON.stringify(staffs));
                        }
                    });
                }else{
                    this.firmStaffs[val] = JSON.parse(window.localStorage.getItem("firmStaffs"+val));
                }
            },
            immediate:true
        }
    },
    data(){
        return {
            staffs:{},
            firmStaffs:{},
            error:false
        }
    },
    methods:{
        onError(e){
            e.target.src = defaultHeader
            e.target.setAttribute('__error', 1)
            this.error = true
            this.$emit('error')
        },
        open(){
            if(this.$refs.image){
                this.$refs.image.getElementsByTagName('img')[0].click()
            }
        },
        load(e){
            if(e.target.getAttribute('__error') != 1){
                this.error = false
            }
            // if(this.preview){
            //     this.$nextTick(()=>{
            //         if(this.$refs.image.$el && this.$refs.image.$el.getElementsByTagName('img')[0]){
            //             this.$refs.image.$el.getElementsByTagName('img')[0].setAttribute('preview', this.preview)
            //         }
            //     })
            // }
        },
        loopFn(arr,staffs){
            arr.forEach(dept=>{
                dept.staffs.forEach(staff=>{
                    staffs[staff.id]=staff;
                })
                if(dept.children.length>0){
                    this.loopFn(item.children,staffs);
                }

            });
        }
    },
    mounted(){
        if(!window.localStorage.getItem("employees") && this.$store.state.user.token){
            this.$http.get(this.$SYSMAN_URL+'/web/staff/firm/list/basedata').then(re=>{
                if(re){
                    let employees = {};
                    re.forEach(item=>{
                        employees[item.id] = item;
                    });
                    this.staffs = employees;
                    localStorage.setItem("employees",JSON.stringify(employees));
                }
            })
        } else {
            this.staffs = JSON.parse(window.localStorage.getItem("employees"));
        }
    }
}
</script>



